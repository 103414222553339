<template>
  <li class="goods-item">
    <div class="content">
      <img
        :src="goods.imgUrls[0]"
        class="cover">
      <div class="info">
        <div class="name">{{ goods.title }}</div>
        <div class="comment">{{ goods.salesTip ? ('近30天销量: ' + sales) : ('评论: ' + goods.comments + '     好评率: ' + goods.goodCommentsShare + '%') }}</div>
        <div class="shop-name">{{ goods.shopName }}</div>
        <div class="coupon">
          <div class="origin-price">原价: <span class="amount">¥{{ goods.price }}</span></div>
          <div
            v-if="goods.coupon"
            class="discount">{{ goods.coupon }}元券</div>
        </div>
        <div class="price">
          <div class="special-price">券后 <span class="unit">¥</span><span class="integer">{{ specialPrice[0] }}</span><span class="decimal">.{{ specialPrice[1] }}</span></div>
          <div class="commission">返利 ¥{{ goods.commission }}</div>
        </div>
      </div>
    </div>
    <div class="opreate">
      <button
        class="button"
        @click="onBuy">{{ goods.channelId === CHANNEL_TB ? '查看淘口令' : '直达链接' }}</button>
    </div>
  </li>
</template>

<script lang="ts">
import { Goods } from '@/api/goods'
import { defineComponent, PropType, computed, ComputedRef } from 'vue'

import { CHANNEL_TB } from '@/constant'

export default defineComponent({
  name: 'goods-item',
  props: {
    goods: {
      type: Object as PropType<Goods>,
      default: null
    }
  },
  setup (props, { emit }) {
    const specialPrice: ComputedRef<Array<string>> = computed(() => props.goods.couponPrice.toFixed(2).split('.'))
    const sales: ComputedRef<string> = computed(() => {
      if (props.goods.salesTip) {
        if (props.goods.channelId === CHANNEL_TB) {
          const s = Number(props.goods.salesTip)
          if (s >= 100000) {
            return (s / 10000).toFixed(0) + 'W+'
          } else if (s >= 10000 && s < 100000) {
            return (s / 10000).toFixed(1) + 'W'
          }
        }
        return props.goods.salesTip
      }
      return ''
    })
    function onBuy () {
      emit('buy', props.goods)
    }
    return {
      CHANNEL_TB,
      specialPrice: specialPrice.value,
      sales: sales.value,
      onBuy
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/styles/variable.scss";
.goods-item {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
  .content {
    display: flex;
    padding: 10px;
    .cover {
      flex-shrink: 0;
      width: 130px;
      height: 130px;
      border-radius: 4px;
    }
    .info {
      flex: auto;
      margin-left: 10px;
      .name {
        height: 36px;
        font-size: 13px;
        line-height: 18px;
        color: $--color-text-primary;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
      .comment {
        white-space: pre;
        margin-top: 5px;
      }
      .comment, .shop-name {
        height: 15px;
        font-size: 11px;
        color: $--color-text-secondary;
        line-height: 15px;
      }
      .coupon {
        display: flex;
        height: 16px;
        margin-top: 8px;
        .origin-price {
          height: 16px;
          font-size: 11px;
          color: $--color-text-secondary;
          line-height: 16px;
          .amount {
            text-decoration: line-through;
          }
        }
        .discount {
          min-width: 30px;
          height: 16px;
          font-size: 10px;
          color: #fff;
          background: $--color-button-background-primary;
          border-radius: 2px;
          text-align: center;
          line-height: 16px;
          padding: 0 5px;
          position: relative;
          margin-left: 10px;
          &:before,
          &:after {
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 50%;
          }
          &:before {
            left: 0;
            transform: translate(-50%, -50%);
          }
          &:after {
            right: 0;
            transform: translate(50%, -50%);
          }
          margin-left: 10px;
        }
      }
      .price {
        display: flex;
        justify-content: space-between;
        height: 20px;
        margin-top: 10px;
        .special-price {
          height: 20px;
          font-size: 11px;
          font-family: $--family-text-number;
          color: $--color-primary;
          line-height: 20px;
          .unit {
            font-size: 13px;
          }
          .integer {
            font-size: 18px;
          }
          .decimal {
            font-size: 15px;
          }
        }
        .commission {
          min-width: 65px;
          height: 20px;
          font-size: 11px;
          color: $--color-primary;
          background-color: rgba($--color-primary, 0.1);
          border-radius: 10px;
          text-align: center;
          line-height: 20px;
          padding: 0 5px;
        }
      }
    }
  }
  .opreate {
    margin: 0 10px;
    border-top: $--width-border solid $--color-border;
    .button {
      display: block;
      width: 100%;
      font-size: 14px;
      color: $--color-button-text-primary;
      height: 44px;
      background-color: transparent;
      border: 0;
      line-height: 44px;
      &:active {
        color: $--color-button-text-primary-active;
      }
    }
  }
}
</style>
