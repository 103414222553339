
import { Vue, Options } from 'vue-class-component'

import {
  CHANNEL_JD,
  CHANNEL_PDD,
  CHANNEL_TB
} from '@/constant'

import TaobaoCodeModal from './components/TaobaoCodeModal.vue'
import GoodsItem from './components/GoodsItem.vue'
import { Res } from '@/api'
import { Goods, SearchData, ShortUrlData } from '@/api/goods'

@Options({
  components: {
    TaobaoCodeModal,
    GoodsItem
  }
})
export default class Search extends Vue {
  readonly CHANNEL_JD: number = CHANNEL_JD
  readonly CHANNEL_PDD: number = CHANNEL_PDD
  readonly CHANNEL_TB: number = CHANNEL_TB
  readonly SORT_OVERALL: number = 0
  readonly SORT_SALES: number = 1
  readonly SORT_COMMISSION: number = 2

  search: string = ''
  tab: number = CHANNEL_TB
  sort: number = this.SORT_OVERALL
  page: number = 0
  size: number = 10
  count: number = 0
  goods: Array<Goods> = []
  lock: boolean = true
  noData: boolean = false
  pddAuth: boolean = false
  history: Array<string> = []

  openId: string = ''
  unionId: string = ''
  type: number = 2

  taobaoCodeVisible: boolean = false
  taoBaoPwd: string = ''
  taoBaoCover: string = ''
  taoBaoCommission: number = 0

  onClearSearch (): void {
    this.search = ''
  }

  onSearch (): void {
    const search = this.search.replace(/(^\s*)|(\s*$)/g, '')
    if (!search) {
      this.$toast('请输入您要查找的内容')
      this.search = ''
    } else {
      (this.$refs.goodsDom as HTMLDivElement).scrollTop = 0
      this.search = search
      this.history.push(search)
      this.getGoods()
    }
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_Search',
      content: this.search
    })
  }

  onSwitchTab (tab: number): void {
    (this.$refs.goodsDom as HTMLDivElement).scrollTop = 0
    this.tab = tab
    this.getGoods()
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_SwitchTab',
      content: tab
    })
  }

  onSwitchSort (sort: number): void {
    (this.$refs.goodsDom as HTMLDivElement).scrollTop = 0
    this.sort = sort
    this.getGoods()
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_SwitchSort',
      content: sort
    })
  }

  async onBuy (goods: Goods): Promise<void> {
    this.$loading.show()
    const res: Res<ShortUrlData> = await this.$api.goods.generateShareForShortUrl(
      goods.goodsId ? goods.goodsId.toString() : goods.goodsSign,
      goods.channelId,
      this.openId,
      this.type,
      this.unionId
    )
    if (res.code === 200 && res.data) {
      if (goods.channelId === this.CHANNEL_TB) {
        this.taoBaoPwd = res.data.shortUrl
        this.taoBaoCover = goods.imgUrls[0]
        this.taoBaoCommission = goods.commission
        this.taobaoCodeVisible = true
      } else {
        window.location.href = res.data.shortUrl
      }
    }
    this.$loading.hide()
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_Buy',
      content: {
        goodsId: goods.goodsId,
        channelId: goods.channelId
      }
    })
  }

  onCopyPwd (pwd: string): void {
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_CopyPwd',
      content: pwd
    })
  }

  async onPddAuth () {
    const res = await this.$api.goods.hgxmPddAuth(
      this.openId,
      this.type,
      this.unionId
    )
    if (res.code === 200 && res.data) {
      // console.log(res)
      window.location.href = res.data.shortUrl
    }
  }

  async getGoods (page: number = 1): Promise<void> {
    // await this.$nextTick()
    if (this.lock && (page === 1 || this.count > (page - 1) * this.size)) {
      this.lock = false
      this.noData = false
      this.pddAuth = false
      const res: Res<SearchData> = await this.$api.goods.goodsKeywords(
        (this.search ? this.search : (this.history.length > 0 ? this.history[this.history.length - 1] : '')),
        this.tab,
        this.sort,
        page,
        this.size,
        this.openId,
        this.type,
        this.unionId
      )
      if (res.code === 50001 && this.tab === this.CHANNEL_PDD) {
        this.noData = true
        this.pddAuth = true
        this.goods = []
        this.count = 0
        this.page = 1
      } else if (res.code === 200 && res.data) {
        if (page === 1) {
          this.goods = res.data.list ? res.data.list : []
          if (this.goods.length === 0 || res.data.totalCount === 0) {
            this.noData = true
          }
        } else {
          this.goods = this.goods.concat(res.data.list ? res.data.list : [])
        }
        this.count = res.data.totalCount ? res.data.totalCount : 0
        this.page = page
      }
      this.lock = true
    }
  }

  onLoadGoods (e: Event): void {
    const target: HTMLDivElement = e.target as HTMLDivElement
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      this.getGoods(this.page + 1)
    }
  }

  created (): void {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
    const goodsKey: string | null = searchParams.get('goodsKey')
    const openId: string | null = searchParams.get('openId')
    const unionId: string | null = searchParams.get('unionId')
    const type: string | null = searchParams.get('type')
    if (goodsKey) {
      this.search = goodsKey
      this.history.push(goodsKey)
    }
    if (openId) {
      this.openId = openId
    }
    if (unionId) {
      this.unionId = unionId
    }
    if (type) {
      this.type = Number(type)
    }
    this.getGoods()

    if (
      window.navigator.userAgent &&
      /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(window.navigator.userAgent)
    ) {
      // 触发 ios css :active
      document.body.addEventListener('touchstart', _ => _)
    }
  }
}
