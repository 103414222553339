<template>
  <transition name="taobao-code-modal-fade">
    <div
      v-show="visible"
      class="taobao-code-modal">
      <div
        v-show="visible"
        class="container">
        <div class="body">
          <div class="cover">
            <img
              class="cover-image"
              :src="cover">
            <div class="tag">购买返¥{{ commission }}</div>
          </div>
          <div class="code">{{ pwd }}</div>
          <button
            class="copy"
            @click="onCopy">一键复制淘口令</button>
          <div class="prompt">
            <p class="text">① 点一键复制后回到淘宝App</p>
            <p class="text">② 弹出商品框后点击打开</p>
            <p class="text">③ 购买成功后会收到返利信息</p>
          </div>
        </div>
        <button
          class="close"
          @click="onClose"/>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import toast from '@/components/package/toast'
import { copy } from '@/utils'

export default defineComponent({
  name: 'taobao-code-modal',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    pwd: {
      type: String as PropType<string>,
      default: ''
    },
    cover: {
      type: String as PropType<string>,
      default: ''
    },
    commission: {
      type: Number as PropType<number>,
      default: 0
    }
  },
  setup (props, { emit }) {
    function onClose (): void {
      emit('close')
      emit('update:visible', false)
    }
    function onCopy (): void {
      copy(props.pwd)
      toast.toast({
        message: '口令复制成功，请到淘宝APP打开',
        duration: 2500
      })
      emit('copy', props.pwd)
      // emit('update:visible', false)
    }
    return {
      onClose,
      onCopy
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/styles/variable.scss";

.taobao-code-modal-fade-enter-active {
  animation: taobao-code-modal-fade-in 300ms;
}

.taobao-code-modal-fade-leave-active {
  animation: taobao-code-modal-fade-out 300ms;
}

@keyframes taobao-code-modal-fade-in {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes taobao-code-modal-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}

.taobao-code-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  .container {
    .body {
      width: 270px;
      background-color: #fff;
      border-radius: 8px;
      padding: 90px 20px 25px 20px;
      position: relative;
      .cover {
        width: 130px;
        height: 130px;
        position: absolute;
        left: 95px;
        top: -65px;
        .cover-image {
          width: 130px;
          height: 130px;
          border-radius: 4px;
          box-shadow: 0 2px 4px 1px rgba(#000, 0.1);
        }
        .tag {
          min-width: 60px;
          height: 20px;
          font-size: 10px;
          color: #fff;
          background: $--color-background-primary;
          border-radius: 10px 0 0 10px;
          line-height: 20px;
          text-align: center;
          padding: 0 10px;
          position: absolute;
          right: -5px;
          bottom: 10px;
          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-width: 2px 2.5px 2px 2.5px;
            border-style: solid;
            border-color: transparent transparent #c9002c #c9002c;
            position: absolute;
            top: -4px;
            right: 0;
          }
        }
      }
      .code {
        font-size: 14px;
        color: $--color-text-primary;
        background-color: rgba($--color-primary, 0.05);
        border-radius: 4px;
        border: 1px dashed $--color-primary;
        word-break: break-all;
        line-height: 30px;
        text-align: center;
        padding: 0 15px;
      }
      .copy {
        display: block;
        width: 200px;
        height: 36px;
        font-size: 14px;
        color: #fff;
        background: $--color-button-background-primary;
        border-radius: 18px;
        border: 0;
        line-height: 36px;
        margin: 25px auto 0 auto;
        &:active {
          background: $--color-button-background-primary-active;
        }
      }
      .prompt {
        width: 180px;
        height: 60px;
        margin: 25px auto 0 auto;
        .text {
          font-size: 12px;
          color: $--color-text-secondary;
          line-height: 20px;
        }
      }
    }
    .close {
      display: block;
      width: 26px;
      height: 26px;
      background: url('~@/assets/images/icons/close.png') no-repeat 0 0/100% 100%;
      border: 0;
      margin: 20px auto 0 auto;
    }
  }
}
</style>
