
import { defineComponent, PropType } from 'vue'
import toast from '@/components/package/toast'
import { copy } from '@/utils'

export default defineComponent({
  name: 'taobao-code-modal',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    pwd: {
      type: String as PropType<string>,
      default: ''
    },
    cover: {
      type: String as PropType<string>,
      default: ''
    },
    commission: {
      type: Number as PropType<number>,
      default: 0
    }
  },
  setup (props, { emit }) {
    function onClose (): void {
      emit('close')
      emit('update:visible', false)
    }
    function onCopy (): void {
      copy(props.pwd)
      toast.toast({
        message: '口令复制成功，请到淘宝APP打开',
        duration: 2500
      })
      emit('copy', props.pwd)
      // emit('update:visible', false)
    }
    return {
      onClose,
      onCopy
    }
  }
})
