<template>
  <div
    class="search-goods"
    v-page-module="'HGXM_SearchPage'">
    <div class="header">
      <div class="search">
        <div class="content">
          <i class="icon"/>
          <input
            v-model="search"
            placeholder="请输入你想搜索的商品"
            class="inner"/>
          <button
            v-if="!!search"
            class="clear"
            @click="onClearSearch"/>
        </div>
        <button
          class="button"
          @click="onSearch">搜索</button>
      </div>
      <div class="nav">
        <ul class="list">
          <li
            :class="[
              'item',
              {
                'active': tab === CHANNEL_TB
              }
            ]"
            @click="onSwitchTab(CHANNEL_TB)">淘宝</li>
          <li
            :class="[
              'item',
              {
                'active': tab === CHANNEL_JD
              }
            ]"
            @click="onSwitchTab(CHANNEL_JD)">京东</li>
          <li
            :class="[
              'item',
              {
                'active': tab === CHANNEL_PDD
              }
            ]"
            @click="onSwitchTab(CHANNEL_PDD)">拼多多</li>
        </ul>
        <div :class="['indicator', 'active-' + tab]"/>
      </div>
      <ul class="sort">
        <li
          :class="[
            'item',
            {
              'active': sort === SORT_OVERALL
            }
          ]"
          @click="onSwitchSort(SORT_OVERALL)">综合排序</li>
        <li
          :class="[
            'item',
            {
              'active': sort === SORT_SALES
            }
          ]"
          @click="onSwitchSort(SORT_SALES)">买的最多</li>
        <li
          :class="[
            'item',
            {
              'active': sort === SORT_COMMISSION
            }
          ]"
          @click="onSwitchSort(SORT_COMMISSION)">返利最高</li>
      </ul>
    </div>
    <div
      class="body"
      ref="goodsDom"
      @scroll="onLoadGoods">
      <template v-if="!noData">
        <ul class="list">
          <goods-item
            v-for="item in goods"
            :key="item.goodsId"
            :goods="item"
            @buy="onBuy"/>
        </ul>
        <div class="more">{{ count > (page - 1) * size ? '加载更多...' : '没有更多了~' }}</div>
      </template>
      <div
        v-else
        class="no-data">
        <img
          src="@/assets/images/no-data.png"
          class="icon">
        <div class="message">
          <p class="text">抱歉！没有找到你想要的商品~</p>
          <p
            v-if="pddAuth"
            class="text">进行拼多多授权，可查询到更多优惠商品，<a class="link" @click="onPddAuth">去授权>></a></p>
        </div>
      </div>
    </div>
    <taobao-code-modal
      v-model:visible="taobaoCodeVisible"
      :pwd="taoBaoPwd"
      :cover="taoBaoCover"
      :commission="taoBaoCommission"
      @copy="onCopyPwd"/>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

import {
  CHANNEL_JD,
  CHANNEL_PDD,
  CHANNEL_TB
} from '@/constant'

import TaobaoCodeModal from './components/TaobaoCodeModal.vue'
import GoodsItem from './components/GoodsItem.vue'
import { Res } from '@/api'
import { Goods, SearchData, ShortUrlData } from '@/api/goods'

@Options({
  components: {
    TaobaoCodeModal,
    GoodsItem
  }
})
export default class Search extends Vue {
  readonly CHANNEL_JD: number = CHANNEL_JD
  readonly CHANNEL_PDD: number = CHANNEL_PDD
  readonly CHANNEL_TB: number = CHANNEL_TB
  readonly SORT_OVERALL: number = 0
  readonly SORT_SALES: number = 1
  readonly SORT_COMMISSION: number = 2

  search: string = ''
  tab: number = CHANNEL_TB
  sort: number = this.SORT_OVERALL
  page: number = 0
  size: number = 10
  count: number = 0
  goods: Array<Goods> = []
  lock: boolean = true
  noData: boolean = false
  pddAuth: boolean = false
  history: Array<string> = []

  openId: string = ''
  unionId: string = ''
  type: number = 2

  taobaoCodeVisible: boolean = false
  taoBaoPwd: string = ''
  taoBaoCover: string = ''
  taoBaoCommission: number = 0

  onClearSearch (): void {
    this.search = ''
  }

  onSearch (): void {
    const search = this.search.replace(/(^\s*)|(\s*$)/g, '')
    if (!search) {
      this.$toast('请输入您要查找的内容')
      this.search = ''
    } else {
      (this.$refs.goodsDom as HTMLDivElement).scrollTop = 0
      this.search = search
      this.history.push(search)
      this.getGoods()
    }
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_Search',
      content: this.search
    })
  }

  onSwitchTab (tab: number): void {
    (this.$refs.goodsDom as HTMLDivElement).scrollTop = 0
    this.tab = tab
    this.getGoods()
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_SwitchTab',
      content: tab
    })
  }

  onSwitchSort (sort: number): void {
    (this.$refs.goodsDom as HTMLDivElement).scrollTop = 0
    this.sort = sort
    this.getGoods()
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_SwitchSort',
      content: sort
    })
  }

  async onBuy (goods: Goods): Promise<void> {
    this.$loading.show()
    const res: Res<ShortUrlData> = await this.$api.goods.generateShareForShortUrl(
      goods.goodsId ? goods.goodsId.toString() : goods.goodsSign,
      goods.channelId,
      this.openId,
      this.type,
      this.unionId
    )
    if (res.code === 200 && res.data) {
      if (goods.channelId === this.CHANNEL_TB) {
        this.taoBaoPwd = res.data.shortUrl
        this.taoBaoCover = goods.imgUrls[0]
        this.taoBaoCommission = goods.commission
        this.taobaoCodeVisible = true
      } else {
        window.location.href = res.data.shortUrl
      }
    }
    this.$loading.hide()
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_Buy',
      content: {
        goodsId: goods.goodsId,
        channelId: goods.channelId
      }
    })
  }

  onCopyPwd (pwd: string): void {
    this.$DR.pageReportFn({
      subModule: 'HGXM_SearchPage_CopyPwd',
      content: pwd
    })
  }

  async onPddAuth () {
    const res = await this.$api.goods.hgxmPddAuth(
      this.openId,
      this.type,
      this.unionId
    )
    if (res.code === 200 && res.data) {
      // console.log(res)
      window.location.href = res.data.shortUrl
    }
  }

  async getGoods (page: number = 1): Promise<void> {
    // await this.$nextTick()
    if (this.lock && (page === 1 || this.count > (page - 1) * this.size)) {
      this.lock = false
      this.noData = false
      this.pddAuth = false
      const res: Res<SearchData> = await this.$api.goods.goodsKeywords(
        (this.search ? this.search : (this.history.length > 0 ? this.history[this.history.length - 1] : '')),
        this.tab,
        this.sort,
        page,
        this.size,
        this.openId,
        this.type,
        this.unionId
      )
      if (res.code === 50001 && this.tab === this.CHANNEL_PDD) {
        this.noData = true
        this.pddAuth = true
        this.goods = []
        this.count = 0
        this.page = 1
      } else if (res.code === 200 && res.data) {
        if (page === 1) {
          this.goods = res.data.list ? res.data.list : []
          if (this.goods.length === 0 || res.data.totalCount === 0) {
            this.noData = true
          }
        } else {
          this.goods = this.goods.concat(res.data.list ? res.data.list : [])
        }
        this.count = res.data.totalCount ? res.data.totalCount : 0
        this.page = page
      }
      this.lock = true
    }
  }

  onLoadGoods (e: Event): void {
    const target: HTMLDivElement = e.target as HTMLDivElement
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      this.getGoods(this.page + 1)
    }
  }

  created (): void {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
    const goodsKey: string | null = searchParams.get('goodsKey')
    const openId: string | null = searchParams.get('openId')
    const unionId: string | null = searchParams.get('unionId')
    const type: string | null = searchParams.get('type')
    if (goodsKey) {
      this.search = goodsKey
      this.history.push(goodsKey)
    }
    if (openId) {
      this.openId = openId
    }
    if (unionId) {
      this.unionId = unionId
    }
    if (type) {
      this.type = Number(type)
    }
    this.getGoods()

    if (
      window.navigator.userAgent &&
      /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(window.navigator.userAgent)
    ) {
      // 触发 ios css :active
      document.body.addEventListener('touchstart', _ => _)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variable.scss";
.search-goods {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $--color-background;
  .header {
    flex-shrink: 0;
    background-color: #fff;
    .search {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 15px;
      .content {
        display: flex;
        align-items: center;
        flex: auto;
        height: 36px;
        background-color: #f1f1f1;
        border-radius: 18px;
        .icon {
          flex-shrink: 0;
          width: 14px;
          height: 14px;
          background: url('~@/assets/images/icons/search.png') no-repeat 0 0/100% 100%;
          margin-left: 10px;
        }
        .inner {
          flex: auto;
          height: 20px;
          font-size: 14px;
          color: $--color-text-primary;
          background-color: transparent;
          border: 0;
          line-height: 20px;
          padding: 0 10px;
          &::placeholder {
            color: $--color-text-placeholder;
          }
        }
        .clear {
          flex-shrink: 0;
          width: 16px;
          height: 16px;
          background: url('~@/assets/images/icons/clear-gray.png') transparent no-repeat 0 0/100% 100%;
          border: 0;
          margin-right: 10px;
        }
      }
      .button {
        flex-shrink: 0;
        width: 60px;
        height: 36px;
        font-size: 14px;
        color: $--color-button-text-primary;
        line-height: 34px;
        border-radius: 18px;
        border: 1px solid $--color-button-border-primary;
        background-color: transparent;
        margin-left: 10px;
        &:active {
          color: $--color-button-text-primary-active;
          border: 1px solid $--color-button-border-primary-active;
        }
      }
    }
    .nav {
      border-bottom: $--width-border solid $--color-border;
      .list {
        display: flex;
        height: 40px;
        .item {
          width: (100% / 3);
          height: 40px;
          font-size: 16px;
          color: $--color-text-primary;
          text-align: center;
          line-height: 40px;
          &.active {
            font-size: 18px;
            font-weight: 600;
            color: $--color-primary;
          }
        }
      }
      .indicator {
        width: 20px;
        height: 3px;
        background: $--color-background-primary;
        border-radius: 2px;
        transform: translateX(-50%);
        transition: margin-left 300ms linear 0ms;
        $--tab-list: '3', '1', '2';
        $--tab-length: 3;
        $--i: 0;
        @each $--tab in $--tab-list {
          $--i: $--i + 1;
          &.active-#{$--tab} {
            margin-left: (100% / ($--tab-length * 2) * ($--i * 2 - 1));
          }
        }
      }
    }
    .sort {
      display: flex;
      align-items: center;
      height: 40px;
      .item {
        width: (100% / 3);
        height: 14px;
        font-size: 13px;
        color: $--color-text-secondary;
        text-align: center;
        line-height: 14px;
        box-sizing: border-box;
        border-left: $--width-border solid $--color-border;
        &:first-child {
          border-left: 0;
        }
        &.active {
          color: $--color-primary;
        }
      }
    }
  }
  .body {
    flex: auto;
    padding: 10px;
    overflow-y: scroll;
    .list {}
    .more {
      font-size: 13px;
      color: $--color-text-secondary;
      text-align: center;
      padding: 20px 0;
    }
    .no-data {
      padding-top: 30%;
      .icon {
        display: block;
        width: 104px;
        height: 68px;
        margin: 0 auto;
      }
      .message {
        .text {
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: $--color-text-secondary;
          text-align: center;
          line-height: 14px;
          margin-top: 20px;
          .link {
            color: $--color-primary;
          }
        }
      }
    }
  }
}
</style>
