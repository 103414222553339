
import { Goods } from '@/api/goods'
import { defineComponent, PropType, computed, ComputedRef } from 'vue'

import { CHANNEL_TB } from '@/constant'

export default defineComponent({
  name: 'goods-item',
  props: {
    goods: {
      type: Object as PropType<Goods>,
      default: null
    }
  },
  setup (props, { emit }) {
    const specialPrice: ComputedRef<Array<string>> = computed(() => props.goods.couponPrice.toFixed(2).split('.'))
    const sales: ComputedRef<string> = computed(() => {
      if (props.goods.salesTip) {
        if (props.goods.channelId === CHANNEL_TB) {
          const s = Number(props.goods.salesTip)
          if (s >= 100000) {
            return (s / 10000).toFixed(0) + 'W+'
          } else if (s >= 10000 && s < 100000) {
            return (s / 10000).toFixed(1) + 'W'
          }
        }
        return props.goods.salesTip
      }
      return ''
    })
    function onBuy () {
      emit('buy', props.goods)
    }
    return {
      CHANNEL_TB,
      specialPrice: specialPrice.value,
      sales: sales.value,
      onBuy
    }
  }
})
